import React, {useState, useEffect} from 'react';
import Header from '../components/header';
import Loader from '../assests/icons/loader.svg';
import FineTuning from './FineTuning';




const CuratingPlan = ({dialogText}) => {
    const [showNewComponent, setShowNewComponent] = useState(false);

useEffect(() => {
    const timer = setTimeout(() => {
        setShowNewComponent(true);
    }, 5000); // 5 seconds

    // Cleanup the timer if the component unmounts before 5 seconds
    return () => clearTimeout(timer);
}, []);

if (showNewComponent) {
    return <FineTuning dialogText={dialogText}/>; // Render the new component after 5 seconds
}

    return(
        
        <div className='relative h-full  bg-custom-gray'>
            <Header/>
            <div className='w-max-[1076px] mx-auto bg-custom-gray h-auto sm:h-full p-6 flex justify-center items-center pt-120px sm:pt-auto frame-border-gradient'>
                <div className='Plans-dialogue'>
                <div className="w-[100%] sm:max-h-[632px] sm:h-full py-14 bg-[#3f3c45]/30 rounded-[40px] shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-center items-center gap-[72px] inline-flex px-4 sm:px-8">
                    <div className="self-stretch text-center text-white text-[37px] font-light font-['Outfit'] leading-[64px]">Curating Plans for you...</div>
                        <div className="w-[100px] h-[100px] justify-center items-center inline-flex">
                            <img src={Loader} className="spin-slow w-full h-full" alt='loader'/>
                        </div>
                        
                        <div className="self-stretch text-center text-[#aeadb2] text-2xl font-light font-['Outfit']">Our state-of-the-art AI is diligently working to create personalized<br/>plans for you. This process will be completed shortly.</div>
                    </div>
                </div>
                </div>
            </div>
    )
};

export default CuratingPlan;