import React from 'react';
import Header from '../components/header';
import Card from './Card';
import VectorWhite from '../assests/icons/Vector-white.svg'




  const getDifficultyLabel = (level) => {
    switch (level) {
        case 1:
            return "Easy";
        case 2:
            return "Moderate";
        case 3:
            return "Difficult";
        case 4:
            return "Challenging";
        default:
            return "Challenging";
    }
};

//   {
//     "name": string,
//     "duration_in_months": number,
//     "days_per_week": number,
//      "repetitions": number,
//     "difficulty_level": number,
//     "plan_detail": string
//  }


const Plan = ({plans,dialogText, profession, selectedAge }) => {

  return (
    <div className="relative bg-custom-gray">
      <Header />
      <div className="bg-custom-gray p-6 flex justify-center items-center  h-auto sm:h-full frame-border-gradient">
      <div className='Plans-dialogue mt-[20px] sm:mt-[60px] lg:mt-[120px] mb-[20px] sm:mb-[60px] lg:mb-[120px]'>
        <div className="h-full p-4 sm:p-8 bg-[#3f3c45]/30 rounded-[40px] shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-start items-start gap-[69px] inline-flex">
          <div className="self-stretch h-full flex-col justify-start items-start gap-3 flex">
            <div className='w-full'>
                  <div className="flex-col justify-center items-center gap-3 inline-flex w-full">
                      <div className="self-stretch text-center text-white text-[37px] font-light font-['Outfit'] leading-[64px]">Here are the plans for you</div>
                      <div className="self-stretch text-center text-[#aeadb2] text-2xl font-light font-['Outfit']">Discover your customized habit plans tailored specifically to you.</div>
                      <div className="self-stretch pt-4 justify-center items-center gap-4 inline-flex">
                        <div className="px-5 py-2 rounded-[999px] border border-[#4fd049] justify-center items-center gap-3 flex">
                          <div className="text-white text-base font-normal font-['Outfit']">Create Plan Manually</div>
                          <div className="w-4 h-4 px-0.5 py-[3px] opacity-0 justify-center items-center flex" />
                        </div>
                        <div className="px-5 py-2 bg-gradient-to-r from-[#4fd049] to-[#cbc435] rounded-[999px] justify-center items-center gap-3 flex">
                        <div className="w-[18.32px]">
                          <img
                            src={VectorWhite}
                            alt="Vector Icon"
                            className="w-full h-full"
                          />
                        </div>
                        <div className="text-[#06030b] text-base font-normal font-['Outfit']">Generate More Plans</div>
                        </div>
                      </div>
                      </div>
              </div>
            <div className='card-section w-full'>
            <div className="w-full pt-10 flex-col justify-start items-center gap-16 inline-flex">
                <div className='w-full max-w-[1076px] card-container'>
                    <div className="self-stretch flex-col justify-start items-center gap-4 flex">
                        <div className="self-stretch gap-4 grid grid-cols-1 sm:grid-cols-2 gap-6">
                        {plans.map((card, index) => (
                            <Card
                            key={index}
                            title={card.name}
                            interestedCount={'100+'}
                            planDuration={`${card.duration_in_months} Month`}
                            details={card.plan_detail}
                            difficultyLevel={getDifficultyLabel(card.difficulty_level)}
                            selectedAge={selectedAge} profession={profession} dialogText={dialogText}
                            />
                        ))}
                        </div>
                    </div>
                </div>
                </div>
            </div>        
        </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
