import React from 'react';
import { Menu } from '@headlessui/react';

const DropdownProfession = ({ profession, setProfession }) => {
  const professions = ['Artist/Designer', 'Business Owner', 'Consultant', 'Doctor/Healthcare Professional','Engineer','Entrepreneur','Freelancer','Lawyer/Legal Professional','Marketing/PR Specialist','Sales/Account Manager','Software Engineer','Student','Teacher/Educator','Writer/Journalist','Other']; // Add more professions here

  return (
    <div className="drop-down w-full">
      <Menu as="div" className="relative inline-block text-left w-full">
        <div className="self-stretch pl-8 pr-4 py-4 bg-[#3f3c45]/30 rounded-[999px] border-2 border-[#302d38] justify-start items-center gap-[73px] inline-flex w-full">
          <Menu.Button className="flex items-center justify-between w-full text-left">
            <div className="grow shrink basis-0 h-5 justify-start items-center gap-2 flex">
              <span className="text-[#aeadb2] text-base font-normal font-['Outfit']">
                {profession || 'Select Profession...'}
              </span>
            </div>
            <div className="p-1.5 bg-[#3f3c45]/30 rounded-[99px] justify-start items-center gap-2 flex">
              <div className="w-5 h-5 px-[3.12px] pt-[6.88px] pb-[5.62px] justify-center items-center flex">
                ▼
              </div>
            </div>
          </Menu.Button>
        </div>

        <Menu.Items className="absolute left-0 right-0 mt-2 bg-[#3f3c45] rounded-lg shadow-lg z-10 h-[200px] overflow-auto">
          {professions.map((prof) => (
            <Menu.Item key={prof}>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-[#302d38] text-white' : 'text-[#aeadb2]'
                  } group flex w-full px-4 py-2 text-sm font-['Outfit']`}
                  onClick={() => setProfession(prof)}
                >
                  {prof}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default DropdownProfession;
