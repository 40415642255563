import React, { useState } from "react";
import AnimatedText from "../components/AnimatedText";
import Card from "../components/Card";
import InfoCard1 from "../components/InfoCard1";
import InfoCard2 from "../components/InfoCard2";
import frame712 from "../assests/images/Frame 712.png";
import frame711 from "../assests/images/Frame 711.png";
import frame713 from "../assests/images/Frame 713.png";
import frame714 from "../assests/images/Frame 714.png";
import CheckCircle from "../assests/icons/CheckCircle.svg";
import Vector from "../assests/icons/Vector.svg";
import FAQ from "../components/FAQ";
import ProfileImage from "../assests/images/Profile image.png";
import PromptSvg from "../assests/icons/Frame2.svg";
import CuratingPlan from "../components/CuratingPlan";
import { Dialog } from "@headlessui/react";

const cardsData = [
  {
    title: "Swimming Lessons",
    interestedCount: "700+",
    planDuration: "4 Month",
    details:
      "Take 1 hour swimming lessons every Monday, Wednesday and Friday for 4 months",
    difficultyLevel: "Difficulty",
  },
  {
    title: "Outdoor Running Challenge",
    interestedCount: "250+",
    planDuration: "2 Month",
    details: "Start with light jog",
    difficultyLevel: "Challenging",
  },
  {
    title: "Daily Journaling Habit",
    interestedCount: "150+",
    planDuration: "6 Month",
    details: "Write thoughts and reflections",
    difficultyLevel: "Easy",
  },
  {
    title: "Digital Detox Challenge",
    interestedCount: "100+",
    planDuration: "1 Month",
    details: "Limit screen time and enjoy nature",
    difficultyLevel: "Moderate",
  },
];

const firstCardData = {
  head: "Let AI Take Care of Your Habits, So You Can Take Care of Yourself",
  text: "Let AI manage your habits, giving you more time to focus on yourself. With smart insights and tailored suggestions, our app keeps you on track, so you can achieve your goals effortlessly.",
  image: frame712,
};
const secondCardData = {
  head: "Measure Your Progress with the Power of AI-Driven Insights",
  text: "AI-Driven Goal Tracking offers personalized insights by analyzing your emotional patterns, helping you identify triggers and optimize routines for improved well-being. Turn emotional awareness into actionable growth with tailored real-time insights.",
  image: frame711,
};
const thirdCardData = {
  head: "Enhanced Goals Tracking for Tailored Insights",
  text: "Track your progress with AI-driven insights that offer real-time feedback and personalized tips. Turn daily habits into lasting achievements with smarter, data-backed guidance.",
  image: frame713,
};

const forthCardData = {
  head: "Journal, reflect and look back",
  text: "Track your progress with our AI-powered app. Journal, reflect, and uncover patterns to understand what drives your success and where to improve. Learn from your habits to build a better future.",
  image: frame714,
};

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [dialogText, setDialogText] = useState("");

  const toggleCuratingPlan = () => {
    setDialogText(inputPrompt);
    setIsOpen(true);
  };

  return (
    <div className="home-page">
      <div className="hero-section relative overflow-hidden">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          muted
          loop
        >
          <source
            src="https://s3-figma-videos-production-sig.figma.com/video/TEAM/926805057301105457/92f78d9aae4ec7319b89aad2ead36729758957c5?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ec2siUc7dXvVof3NEUVorPue8ayKjf95J5IUUN6tf6ll47pdCBiu5Zv~fh0oWmszwzam9EKoVn0BzE-kcwU3SW4-lvsC-qtUaylQVRnMANg9ckElhHFsQH4W6bMMfTf13IqeJOxej2Vu3TiOi-YCOAybyaPaD3d0mYWvOV0hOMZ-uMj8a3LrXXUWJwcODHepx97cFW0YMAVV53TyGNNNt5Kkl4cXEZPzGTPgci-efPh4p~nXcAKR-C1tHNxRXxphWF9vsdzgrK5XVrCgaRZO~38MO8wEAJmcWxwG5j2g63e4Pc1nolKF38p8zBc5Y7BDORrcmaCLW2z8hrIxiiLP0A__"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <div className="w-full px-8 sm:px-16 pt-[120px] pb-24 from-[#06030b] via-[#06030b] to-[#06030b] flex-col justify-start items-center gap-[72px] inline-flex relative z-10 hero-container">
          <div className="self-stretch flex-col justify-start items-center gap-8 flex">
            <div className="self-stretch flex-col justify-center items-center flex">
              <div className="self-stretch text-center text-white text-[56px] font-light font-['Outfit'] leading-[64px]">
                Discover the
              </div>
              <div className="inline-flex flex-col gap-2 sm:flex-row sm:gap-4 md:flex-row md:gap-4 self-stretch justify-center items-center animated-text-container">
                <div className="text-[#302d38] text-[56px] font-light font-['Outfit'] leading-[64px] text-center sm:text-left sm:justify-start sm:items-start text-era text-gradient">
                  New Era of
                </div>
                <div className="flex-col justify-start items-center inline-flex">
                  <div className="w-full justify-start items-center inline-flex">
                    <div className="flex-col justify-center items-start inline-flex ">
                      <AnimatedText />
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch text-center text-white text-[56px] font-light font-['Outfit'] leading-[64px]">
                With Powerful AI
              </div>
            </div>
            <div className="self-stretch justify-center items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 text-center text-[#aeadb2] text-2xl font-light font-['Outfit']">
                Gain personalized recommendations and real-time analytics to
                optimize your daily habits.
              </div>
            </div>
          </div>
          <div className="Prompt">
            <div className="w-full max-w-[1076px] self-stretch px-6 pt-4 pb-8 bg-[#3f3c45]/30 rounded-[40px] shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-center items-center gap-6 flex sm:px-8">
              <div className="self-stretch text-[#aeadb2] text-lg font-normal font-['Outfit']">
                Define your goal in a short one-to-two-sentence statement
              </div>
              <div className="self-stretch h-77 sm:h-16 pl-6 pr-2 py-4 bg-[#3f3c45]/30 rounded-[999px] border-2 border-[#302d38] flex flex-col justify-between items-center sm:inline-flex sm:flex-row">
                <div className="flex items-center bg-transparent px-4 py-2 w-full sm:w-[69%] lg:w-[80%]">
                  {/* SVG Icon as Image */}
                  <img
                    src={PromptSvg}
                    alt="Icon"
                    className="w-5 h-5 text-[#aeadb2] mr-3"
                  />

                  {/* Input Field */}
                  <input
                    type="text"
                    value={inputPrompt}
                    onChange={(e) => {
                      console.log(e)
                      setInputPrompt(e.target.value);
                    }}
                    placeholder="e.g. Learn to play guitar in 4 months"
                    className="bg-transparent outline-none text-[#aeadb2] text-base font-medium font-['Outfit'] flex-grow"
                  />
                </div>
                <button
                  onClick={toggleCuratingPlan}
                  className="hidden md:flex px-5 py-2 rounded-full border border-gradient rounded-[999px] text-white text-sm font-['Outfit'] gap-2 goalSubmitted"
                >
                  <div className="w-[18.32px]">
                    <img
                      src={Vector}
                      alt="Vector Icon"
                      className="w-full h-full"
                    />
                  </div>
                  Generate Plans
                </button>

                {/* Full-Screen Modal */}
                <Dialog
                  open={isOpen}
                  onClose={() => setIsOpen(false)}
                  className="relative z-50"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center h-full">
                    <Dialog.Panel className="bg-custom-gray w-full h-full overflow-auto shadow-lg relative">
                      {/* Close Button */}
                      <button
                        onClick={() => setIsOpen(false)}
                        className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                      >
                        ✕
                      </button>

                      {/* CuratingPlan Component */}
                      <CuratingPlan dialogText={dialogText} />
                    </Dialog.Panel>
                  </div>
                </Dialog>
              </div>
              <button
                onClick={toggleCuratingPlan}
                className="w-full border-gradient flex justify-center align-center md:hidden px-5 py-2 rounded-full border border-[#4fd049] text-white text-sm font-['Outfit'] gap-2"
              >
                <div className="w-[18.32px]">
                  <img
                    src={Vector}
                    alt="Vector Icon"
                    className="w-full h-full"
                  />
                </div>
                Generate Plans
              </button>

              <div className="self-stretch justify-start items-center gap-4 flex flex-wrap">
                {[
                  "Learn to cook three new recipes",
                  "Practice meditation for 10 minutes daily",
                  "Learn about investing and start a small portfolio",
                  "Explore local hiking trails",
                  "Plan a family movie night each week",
                ].map((label) => (
                  <button
                    key={label}
                    onClick={() => {
                      console.log(label)
                      setDialogText(label);
                      setIsOpen(true);
                    }}
                    className="px-5 py-2 rounded-[999px] border border-[#7dcc78] justify-center items-center gap-3 flex text-white text-sm font-normal font-['Outfit'] border-gradient"
                  >
                    <div className="w-[18.32px] relative">
                      <img
                        src={Vector}
                        alt="Vector Icon"
                        className="w-full h-full"
                      />
                    </div>
                    {label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-section">
        <div className="w-full bg-custom-gray px-8 sm:px-16 pt-10 pb-24 flex-col justify-start items-center gap-16 inline-flex">
          <div className="self-stretch text-center text-white text-4xl font-light font-['Outfit']">
            See what others are planning
          </div>
          <div className="w-full max-w-[1076px] card-container">
            <div className="self-stretch flex-col justify-start items-center gap-4 flex">
              <div className="self-stretch gap-4 grid grid-cols-1 sm:grid-cols-2 gap-6">
                {cardsData.map((card, index) => (
                  <Card
                    key={index}
                    title={card.title}
                    interestedCount={card.interestedCount}
                    planDuration={card.planDuration}
                    details={card.details}
                    difficultyLevel={card.difficultyLevel}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="powered-habits" id="features">
        <div className="w-full bg-custom-gray px-8 sm:px-16 pt-10 pb-24 flex-col justify-start items-center gap-16 inline-flex">
          <div className="self-stretch flex-col justify-start items-center gap-4 flex">
            <div className="self-stretch text-center text-white text-4xl font-light font-['Outfit']">
              AI-powered features for Habits
            </div>
            <div className="px-5 py-2 rounded-[999px] border border-[#302d38] justify-center items-center gap-3 inline-flex">
              <div className="text-[#7dcc78] text-base font-light font-['Outfit']">
                Try 30+ Ai Habit Tracking features today
              </div>
              <div className="w-4 h-4 relative" />
            </div>
          </div>
          <div className="self-stretch flex-col justify-start items-center gap-4 flex">
            <div className="w-full max-w-[1076px] mx-auto self-stretch justify-center items-center inline-flex flex-col gap-8 sm:inline-flex sm:flex-row sm:gap-4">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-8 inline-flex">
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-8 h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[22px] font-normal font-['Outfit']">
                    Recurring AI events
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-8 h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[22px] font-normal font-['Outfit']">
                    Flexible time holds
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-8 h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[22px] font-normal font-['Outfit']">
                    Smart time windows (for scheduling)
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-8 h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[22px] font-normal font-['Outfit']">
                    Auto-rescheduling for conflicts (PTO, meetings, etc.)
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-8 h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[22px] font-normal font-['Outfit']">
                    Priority ranking
                  </div>
                </div>
              </div>
              <div className="w-full grow shrink basis-0 flex-col justify-start items-start gap-8 inline-flex">
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-8 h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[22px] font-normal font-['Outfit']">
                    Habit tracking analytics
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-8 h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[22px] font-normal font-['Outfit']">
                    Work, personal, & family Habit templates
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-8 h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[22px] font-normal font-['Outfit']">
                    Min-to-max event durations
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-8 h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[22px] font-normal font-['Outfit']">
                    Slack status sync (during Habit events)
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-8 h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[22px] font-normal font-['Outfit']">
                    Custom privacy & visibility settings
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="info-card-main bg-custom-gray">
        <div className="info-card bg-custom-gray">
          {/* Render the first card separately */}
          <InfoCard1
            head={firstCardData.head}
            text={firstCardData.text}
            image={firstCardData.image}
          />
        </div>
        <div className="info-card bg-custom-gray">
          {/* Render the first card separately */}
          <InfoCard2
            head={secondCardData.head}
            text={secondCardData.text}
            image={secondCardData.image}
          />
        </div>
        <div className="info-card bg-custom-gray">
          {/* Render the first card separately */}
          <InfoCard1
            head={thirdCardData.head}
            text={thirdCardData.text}
            image={thirdCardData.image}
          />
        </div>
        <div className="info-card bg-custom-gray">
          {/* Render the first card separately */}
          <InfoCard2
            head={forthCardData.head}
            text={forthCardData.text}
            image={forthCardData.image}
          />
        </div>
      </div>

      <div className="testimonials bg-custom-gray w-full">
        <div className="px-8 sm:px-16 pt-10 pb-24 flex-col justify-start items-center gap-14 inline-flex w-full ">
          <div className="self-stretch text-center text-white text-4xl font-light font-['Outfit']">
            What people are saying about TrackHabit
          </div>
          <div className="w-full max-w-[1076px] mx-auto self-stretch grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Testimonial 1 */}
            <div className="px-4 pt-4 pb-6 bg-[#3f3c45]/30 rounded-3xl shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-start items-start gap-6">
              <div className="self-stretch flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img
                    className="w-[38.24px] h-[38.24px] rounded-full"
                    src={ProfileImage}
                    alt="Alex R."
                  />
                  <div className="text-white text-base font-normal font-['Outfit']">
                    Alex R.
                  </div>
                </div>
                <div className="flex">
                  {[...Array(5)].map((_, index) => (
                    <div
                      key={index}
                      className="w-5 h-5 relative text-yellow-400"
                    >
                      ★
                    </div>
                  ))}
                </div>
              </div>
              <div className="self-stretch pt-8">
                <div className="text-white text-xl font-normal font-['Outfit']">
                  Effective and user-friendly
                </div>
                <div className="text-[#aeadb2] text-base font-light font-['Outfit']">
                  Since incorporating this AI habit tracker into my routine,
                  I've experienced notable improvements in achieving my goals.
                </div>
              </div>
            </div>

            {/* Testimonial 2 */}
            <div className="px-4 pt-4 pb-6 bg-[#3f3c45]/30 rounded-3xl shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-start items-start gap-6">
              <div className="self-stretch flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img
                    className="w-[38.24px] h-[38.24px] rounded-full"
                    src={ProfileImage}
                    alt="Sophia K."
                  />
                  <div className="text-white text-base font-normal font-['Outfit']">
                    Sophia K.
                  </div>
                </div>
                <div className="flex">
                  {[...Array(4)].map((_, index) => (
                    <div
                      key={index}
                      className="w-5 h-5 relative text-yellow-400"
                    >
                      ★
                    </div>
                  ))}
                </div>
              </div>
              <div className="self-stretch pt-8">
                <div className="text-white text-xl font-normal font-['Outfit']">
                  Transformative tool
                </div>
                <div className="text-[#aeadb2] text-base font-light font-['Outfit']">
                  Using this AI habit tracker has been truly transformative for
                  me. The personalized insights have guided me towards
                  sustainable habits.
                </div>
              </div>
            </div>

            {/* Testimonial 3 */}
            <div className="px-4 pt-4 pb-6 bg-[#3f3c45]/30 rounded-3xl shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-start items-start gap-6">
              <div className="self-stretch flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img
                    className="w-[38.24px] h-[38.24px] rounded-full"
                    src={ProfileImage}
                    alt="Olivia L."
                  />
                  <div className="text-white text-base font-normal font-['Outfit']">
                    Olivia L.
                  </div>
                </div>
                <div className="flex">
                  {[...Array(5)].map((_, index) => (
                    <div
                      key={index}
                      className="w-5 h-5 relative text-yellow-400"
                    >
                      ★
                    </div>
                  ))}
                </div>
              </div>
              <div className="self-stretch pt-8">
                <div className="text-white text-xl font-normal font-['Outfit']">
                  Empowering tool
                </div>
                <div className="text-[#aeadb2] text-base font-light font-['Outfit']">
                  This AI habit tracker has empowered me to take charge of my
                  habits like never before. The personalized insights have
                  guided me towards consistency and growth.
                </div>
              </div>
            </div>

            {/* Testimonial 4 */}
            <div className="px-4 pt-4 pb-6 bg-[#3f3c45]/30 rounded-3xl shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-start items-start gap-6">
              <div className="self-stretch flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img
                    className="w-[38.24px] h-[38.24px] rounded-full"
                    src={ProfileImage}
                    alt="Daniel W."
                  />
                  <div className="text-white text-base font-normal font-['Outfit']">
                    Daniel W.
                  </div>
                </div>
                <div className="flex">
                  {[...Array(5)].map((_, index) => (
                    <div
                      key={index}
                      className="w-5 h-5 relative text-yellow-400"
                    >
                      ★
                    </div>
                  ))}
                </div>
              </div>
              <div className="self-stretch pt-8">
                <div className="text-white text-xl font-normal font-['Outfit']">
                  Consistency booster
                </div>
                <div className="text-[#aeadb2] text-base font-light font-['Outfit']">
                  This AI habit tracker has been a key factor in enhancing my
                  consistency and progress towards my goals. Highly recommended!
                </div>
              </div>
            </div>

            {/* Testimonial 5 */}
            <div className="px-4 pt-4 pb-6 bg-[#3f3c45]/30 rounded-3xl shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-start items-start gap-6">
              <div className="self-stretch flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img
                    className="w-[38.24px] h-[38.24px] rounded-full"
                    src={ProfileImage}
                    alt="David H."
                  />
                  <div className="text-white text-base font-normal font-['Outfit']">
                    David H.
                  </div>
                </div>
                <div className="flex">
                  {[...Array(4)].map((_, index) => (
                    <div
                      key={index}
                      className="w-5 h-5 relative text-yellow-400"
                    >
                      ★
                    </div>
                  ))}
                </div>
              </div>
              <div className="self-stretch pt-8">
                <div className="text-white text-xl font-normal font-['Outfit']">
                  Inspiring progress
                </div>
                <div className="text-[#aeadb2] text-base font-light font-['Outfit']">
                  The insights from this AI habit tracker have inspired
                  remarkable progress in my goal-setting journey.
                </div>
              </div>
            </div>

            {/* Testimonial 6 */}
            <div className="px-4 pt-4 pb-6 bg-[#3f3c45]/30 rounded-3xl shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-start items-start gap-6">
              <div className="self-stretch flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img
                    className="w-[38.24px] h-[38.24px] rounded-full"
                    src={ProfileImage}
                    alt="David H."
                  />
                  <div className="text-white text-base font-normal font-['Outfit']">
                    David H.
                  </div>
                </div>
                <div className="flex">
                  {[...Array(4)].map((_, index) => (
                    <div
                      key={index}
                      className="w-5 h-5 relative text-yellow-400"
                    >
                      ★
                    </div>
                  ))}
                </div>
              </div>
              <div className="self-stretch pt-8">
                <div className="text-white text-xl font-normal font-['Outfit']">
                  Inspiring progress
                </div>
                <div className="text-[#aeadb2] text-base font-light font-['Outfit']">
                  The insights from this AI habit tracker have inspired
                  remarkable progress in my goal-setting journey.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="faqs bg-custom-gray w-full" id="why-habit-tracking">
        <FAQ />
      </div>
    </div>
  );
};

export default Home;
