import React, { useState, useEffect } from "react";
import Header from "./header";
import Loader from "../assests/icons/loader.svg";
import Plan from "./Plan";
import axios from "axios";

const CuratingPlanAfterAge = ({ dialogText, profession, selectedAge }) => {
  const [showNewComponent, setShowNewComponent] = useState(false);
  const [plans, setPlans] = useState([]);
  const [loadingPlans, setLoadingPlans] = useState(false);


  const createPrompt = () => {
    let prompt = `You are an expert in defining a specific goal from a user input statement.
    <user>
        {{user_statement}}
        {{#if user_age}}
        Age: {{user_age}}
        {{/if}}
        {{#if user_profession}}
        Profession: {{user_profession}}
        {{/if}}
    </user>
    
    Identify the goal from the user's statement. If goal can't be extracted respond with goal as null.
    <response as="goal_extracted" />
    
    {{#if goal_extracted}}
    Goal: {{goal_extracted}}
    Suggest plans to achieve the goal using the user's age and/or profession as additional criteria if provided. 
    Show the plans in JSON format with each plan's name, plan duration in month, How many day a week should do this activity, repetition of activity to make it a habit, deficulty level (1, 2 , 3, 4) and plan Detail in 1 line.
    <response as="activities" />
    {{:else}}
    No goal can be extracted from the statement.
    {{/if}}`;
    prompt = prompt.replace("{{user_statement}}", dialogText);
    prompt = prompt.replace("{{user_age}}", selectedAge);
    prompt = prompt.replace("{{user_profession}}", profession);
    return prompt;
  };


  const getPlans = async () => {
    if (loadingPlans) return; // Prevent duplicate calls
    setLoadingPlans(true);
    setShowNewComponent(false);
    try {
      const res = await axios.post("https://staging.trackhabit.io/api/generate/", {
        label: createPrompt(),
      });
      if (res.data) {
        // console.log(res.data);

        const planObj = JSON.parse(res.data.goal);

        setPlans(planObj.data)
      }
      setShowNewComponent(true);
      setLoadingPlans(false);
    } catch (error) {
        setLoadingPlans(false);
      alert("Something wrong please try later!");
    }
  };

  useEffect(() => {
    if (!loadingPlans && plans.length === 0) {
      getPlans();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPlans, plans]); // Include getPlans as a dependency
  



  if (showNewComponent && plans?.length > 0) {
    return <Plan plans={plans} selectedAge={selectedAge} profession={profession} dialogText={dialogText}/>;
  }

  return (
    <div className="relative h-full  bg-custom-gray">
      <Header />
      <div className="w-max-[1076px] mx-auto bg-custom-gray h-auto sm:h-full p-6 flex justify-center items-center pt-120px sm:pt-auto frame-border-gradient">
        <div className="Plans-dialogue">
          <div className="w-[100%] sm:max-h-[632px] sm:h-full py-14 bg-[#3f3c45]/30 rounded-[40px] shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-center items-center gap-[72px] inline-flex px-4 sm:px-8">
            <div className="self-stretch text-center text-white text-[37px] font-light font-['Outfit'] leading-[64px]">
              Fine-tuning your Personalized Plans
            </div>
            <div className="w-[100px] h-[100px] justify-center items-center inline-flex">
              <img
                src={Loader}
                className="spin-slow w-full h-full"
                alt="loader"
              />
            </div>

            <div className="self-stretch text-center text-[#aeadb2] text-2xl font-light font-['Outfit']">
              Our state-of-the-art AI is diligently working to fine-tune
              personalized
              <br />
              plans for you. This process will be completed shortly.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CuratingPlanAfterAge;
