import React, { useState } from 'react';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What are habits of successful people?",
      answer: "TrackHabit utilizes publicly available information from interviews and other sources about successful people to create a list of habits that they may have developed over time to create a curated activity list of habits. ",
    },
    { 
        question: "How TrackHabit can help me in achieving my goals?",
        answer:"Trackhabit acts as your personal advisor trained to suggest activities that help achieve your specific goals. It utilizes timely triggers and notifications to keep you on track. "
    },
    { 
        question: "Can this app help me build routines?",
        answer:"Yes, there is a saying that it takes 21 days to make or break a habit. With that concept in mind TrackHabit utilizes reminders to keep you on track and build routines" 
    },
    { 
        question: "What are the key features of TrackHabit for goal achievement?" ,
        answer:"We are continuously improving our product and will be adding more features as we discover more use cases. At present Key features of TrackHabit include Advising on activities and routines that may lead to success fully achieving goals, reminders, activity tracking, and summary of your progress towards your goals. "
    },
    { 
        question: "How can TrackHabit assist in habit formation?",
        answer:"TrackHabit sends out timely reminders and track status of routine to assist with habit formation. "
    },
    { 
        question: "Are there specific tools in TrackHabit for tracking progress?",
        answer:"TrackHabit uses basic tracking for each activity type and renders them based on schedule to keep track of your progress." 
    },
    { 
        question: "How easy is it to set goals using TrackHabit?" ,
        answer:"TrackHabit utilizes AI in the background to translate your statements into goals. Setting up goals and activity is super easy, just enter your statement as you would talk to a chat bot and leave the rest of it to us to extract goals from your statement and create an activity plan. "
    },
    { 
        question: "Can TrackHabit adjust routines based on progress?" ,
        answer:"We are testing this feature with a beta user group before releasing it to the app. As of now TrackHabit only manages schedules that are entered but we are hopeful to release the feature to the public soon."
    },
    { 
        question: "What support does TrackHabit offer for habit consistency?" ,
        answer:"Timely reminders, tracking and summary of historical activities are primary form of support to help you build habit consistency "
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className='faq-inner mx-auto  w-full max-w-[1076px]'>
        <div className="w-full max-w-[1076px] px-8 sm:px-16 pt-10 pb-24 flex-col justify-start items-center gap-14 inline-flex ">
        <div className="self-stretch text-center text-white text-4xl font-light font-['Outfit']">
            Frequently Asked Questions
        </div>
        
        <div className=" self-stretch h-auto flex-col justify-start items-center gap-4 flex">
            {faqs.map((faq, index) => (
            <div
                key={index}
                className={`self-stretch ${
                activeIndex === index ? "h-auto" : "h-full"
                } px-8 py-6 bg-[#3f3c45]/30 rounded-3xl shadow border border-[#302d38] backdrop-blur-[56px] flex flex-col gap-4 transition-all duration-300`}
            >
                <div
                className="self-stretch flex justify-between items-center cursor-pointer"
                onClick={() => toggleFAQ(index)}
                >
                <div className="text-white text-xl font-normal font-['Outfit']">
                    {faq.question}
                </div>
                <div
                    className={`p-1.5 bg-[#3f3c45]/30 rounded-[99px] flex items-center justify-center transition-transform ${
                    activeIndex === index ? "rotate-180" : ""
                    }`}
                >
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-5 h-5 text-white"
                    >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                    />
                    </svg>
                </div>
                </div>
                {activeIndex === index && (
                <div className="self-stretch text-[#aeadb2] text-base font-light font-['Outfit'] mt-2">
                    {faq.answer || "No answer provided."}
                </div>
                )}
            </div>
            ))}
        </div>
        </div>
    </div>
  );
};

export default FAQ;
