import React from 'react';

const InfoCard1 = ({ head, text, image }) => {
  return (
    <div className='info-card1-text-img'>
        <div className="w-full bg-custom-gray justify-center items-center gap-[72px] inline-flex">
            <div className='w-full max-w-[1076px] info-card-inner justify-center items-center gap-[72px] flex flex-col-reverse px-8 sm:px-16 pt-10 pb-24 lg:flex-row lg:px-4 lg:pt-4 lg:pb-6'>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-6 inline-flex">
                    <div className="self-stretch text-white text-4xl font-normal font-['Outfit']">{head}</div>
                    <div className="self-stretch text-[#aeadb2] text-2xl font-normal font-['Outfit']">{text}</div>
                </div>
                <div className="">
                    <div className="">
                    <div className="">
                        <img
                        src={image}
                        alt="Dynamic Content"
                        className="w-full w-full  object-cover"
                        />
                        {/* Add any other overlay elements here if needed */}
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default InfoCard1;
