import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header'; // Adjust path as needed
import HomePage from './pages/home';
import CuratingPlan from './components/CuratingPlan';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/curating-plan" element={<CuratingPlanModal />} />
      </Routes>
    </Router>
  );
};
const CuratingPlanModal = () => {
  return (
      <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <CuratingPlan />
      </div>
  );
};

export default App;
