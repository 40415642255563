import React, { useState } from 'react';
import Logo from '../assests/icons/Layer_1.svg';
import { Menu, X } from 'react-feather'; // Icons for mobile menu toggle

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
<header className="px-4 py-3 bg-custom-gray shadow border-b border-[#302d38] backdrop-blur-[56px] flex flex-col md:flex-row md:justify-between md:items-center">
  {/* Logo Section */}
  <div className="flex items-center gap-2">
    <div className="h-8 pl-[3.39px] pr-[2.39px] pt-1.5 pb-[3px] flex items-center">
        <a href='/'>
            <img src={Logo} alt="TrackHabit Logo" className="w-[26.22px] h-[23px]" />
        </a>
    </div>
    <a className="text-white text-xl font-normal font-['Outfit']" href='/'>TrackHabit</a>
  </div>

  {/* Desktop Navigation */}
  <nav className="hidden md:flex gap-8">
    {[ 'Features', 'Why Habit Tracking'].map((label, index) => (
      <a
        key={index}
        href={`#${label.toLowerCase().replace(/\s/g, '-')}`}
        className="text-[#aeadb2] text-sm font-normal font-['Outfit'] hover:text-blue-700 dark:hover:text-blue-500"
      >
        {label}
      </a>
    ))}
  </nav>

  {/* Auth Buttons (Desktop Only) */}
   <div className="hidden md:flex items-center gap-3">
    <button className="px-5 py-2 rounded-full border border-[#4fd049] text-white text-sm font-normal font-['Outfit']">
      Get Started
    </button>
  </div> 

  {/* Mobile Menu Toggle */}
  <div className="md:hidden">
    <button onClick={toggleMobileMenu} className="text-white absolute top-[15px] right-[20px]">
      {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
    </button>
  </div>

  {/* Mobile Menu Overlay */}
  {isMobileMenuOpen && (
    <div className="inset-0 bg-black bg-opacity-75 flex flex-col items-center gap-4 pt-5 text-white z-10 md:hidden">
      {[ 'Features', 'Why Habit Tracking'].map((label, index) => (
        <a
          key={index}
          href={`#${label.toLowerCase().replace(/\s/g, '-')}`}
          onClick={toggleMobileMenu}
          className="text-lg font-['Outfit'] hover:text-blue-500"
        >
          {label}
        </a>
      ))}
      {/* <button className="px-5 py-2 rounded-full border border-[#302d38] text-white text-sm font-['Outfit']">
        Log In
      </button>
      <button className="px-5 py-2 rounded-full border border-[#4fd049] text-white text-sm font-['Outfit']">
        Get Started
      </button> */}
    </div>
  )}
</header>

  );
};

export default Header;
