import React, { useState } from 'react';
import Header from '../components/header';
import axios from 'axios';

const EmailSection = ({ dialogText, profession, selectedAge }) => {
  const [email, setEmail] = useState(''); // Manage email input state
  const [emailError, setEmailError] = useState(''); // Manage email validation error state

  // Function to validate email format
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async () => {
    if (!email) {
      alert('Please enter your email address.');
      return;
    }

    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    setEmailError(''); // Clear any previous errors

    // Prepare the data to send to the backend
    const data = {
      email,
      age: selectedAge,
      profession,
      user_prompt: dialogText,
      ai_response: 'Plans data to be included here', // Include the AI response data if applicable
    };

    try {
      // Send the data to your backend API to save to Airtable
      const response = await axios.post('https://staging.trackhabit.io/api/save-user-data/', data);
      alert('Data successfully saved!');
      console.log(response.data); // Optionally log the response for debugging
    } catch (error) {
      alert('Error saving data. Please try again later.');
      console.error(error); // Log the error for debugging
    }
  };

  return (
    <div className="relative h-full bg-custom-gray">
      <Header />
      <div className="bg-custom-gray h-auto sm:h-full p-6 flex justify-center items-center frame-border-gradient">
        <div className="Plans-dialogue">
          <div className="py-14 bg-[#3f3c45]/30 rounded-[40px] shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-center items-center inline-flex px-4 sm:px-8 gap-[40px] sm:gap-[72px] ">
            <div className="self-stretch flex-col justify-start items-start gap-3 flex">
              <div className="self-stretch text-center text-white text-[37px] font-light font-['Outfit'] leading-[64px]">
                Thank you for taking interest
              </div>
              <div className="self-stretch text-center text-[#aeadb2] text-2xl font-light font-['Outfit']">
                We are currently working hard to make TrackHabit available to you. Please provide us with your email address, and we will ensure that you do not miss out on this plan.
              </div>
            </div>
            <div className="self-stretch h-[103px] flex-col justify-start items-start gap-10 flex">
              <div className="self-stretch h-[103px] flex-col justify-start items-start gap-4 flex">
                <div className="self-stretch text-[#aeadb2] text-lg font-light font-['Outfit']">
                  We’ll get back on this email address ASAP...
                </div>
                <div className="w-full">
                  <label htmlFor="emailInput" className="sr-only">Email Address</label>
                  <input
                    type="email"
                    id="emailInput"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email address..."
                    className="w-full px-8 py-4 bg-[#3f3c45]/30 rounded-[999px] border-2 border-[#302d38] text-[#aeadb2] text-base font-normal font-['Outfit'] emailInputScreen"
                  />
                  {emailError && <div className="text-red-500 text-sm mt-2">{emailError}</div>}
                </div>
              </div>
            </div>
            <div className="self-stretch justify-center items-center gap-8 inline-flex w-full sm:w-auto sm:justify-end">
              <button
                onClick={handleSubmit}
                className="w-full h-12 px-5 py-2 rounded-[999px] border border-[#4fd049] justify-center items-center gap-3 flex cursor-pointer sm:w-44 submitButtonEmailScreen"
              >
                <div className="text-white text-base font-normal font-['Outfit'] w-full sm:w-auto text-center ">Submit</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSection;
