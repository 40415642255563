import { useState, useEffect } from "react";

export default function AnimatedText() {
  const items = [
    "Habit Tracking",
    "Routine Planning",
    "Daily Reflection",
    "Setting Goals",
    "Tracking Progress",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 2000); // Adjust timing as needed
    return () => clearInterval(interval);
  }, [items.length]);

  return (
    <div className="w-full justify-end items-center inline-flex">
      <div className="flex-col justify-center items-start test inline-flex">
        <div
          className="animated-text text-center text-[#7dcc78] text-[56px] font-light font-['Outfit'] leading-[64px] fade-in-out sm:text-left sm:justify-start sm:items-start hero-text-gradient"
          key={currentIndex}
        >
          {items[currentIndex]}
        </div>
      </div>
      {/* <style jsx>{`
        @keyframes fadeInOut {
          0%, 100% { opacity: 0; }
          10%, 90% { opacity: 1; }
        }
        .fade-in-out {
          animation: fadeInOut 2s ease-in-out;
        }
      `}</style> */}
    </div>
  );
}
